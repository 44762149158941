body {
    margin: 0;
    padding: 0;
}

:root {
    --itemHeight: 21.59px;
}

.photography__header__main {
    height: calc(100vh - 64px);
    padding: 32px;
    display: flex;
    background-color: rgba(255, 255, 255, .95);
    z-index: 1;
    position: relative;
    backdrop-filter: blur(10px);

    * {
        font-family: "Circular";
        font-weight: 400;
    }

    .logo__section {
        width: 20%;

        img{
            width: 25px;
        }

        p {
            font-size: 13px;
            padding-right: 10px;
            margin-top: 10px;
            color: #000;
        }
    }

    .links__list__section {
        width: 80%;

        .list__section {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .list__item {
                width: 100%;
                display: flex;
                height: var(--itemHeight);

                div {
                    width: 17%;
                    font-size: 12px;
                    line-height: 16px;
                    color: #000;

                    &.description__section {
                        width: 66%;
                        text-align: end;
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                        font-size: 12px;
                    }
                }

                .description__section,
                .slogan {
                    opacity: 0;
                }

                &.list_hover {
                    a {
                        opacity: .4;
                    }
                }

                &.active_list_item {

                    .description__section,
                    .slogan,
                    a {
                        opacity: 1;
                    }
                }

                .description__section,
                .slogan,
                a {
                    transition: .2s opacity;
                }
            }

            .list__item__btn {
                width: 100%;
                height: var(--itemHeight);
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                opacity: .4;
                padding: 0;

                a {
                    text-decoration: none;
                    color: #000;
                    display: none;
                }

                &.active__btn {
                    opacity: 1;

                    a {
                        display: block;
                    }
                }

                .name__section {
                    text-align: left;
                    min-width: 160px;
                }

                .link__section {
                    text-align: end;
                }
            }
        }
    }
}

.img__container {
    width: calc(100% - 200px);
    height: calc(100vh - 200px);
    padding: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;

    }
}

@media only screen and (max-width:1220px) {
    .photography__header__main {
        .links__list__section {
            .list__section {
                .list__item {
                    div {
                        width: 23%;

                        &.description__section {
                            width: 54%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:899px) {
    .img__container {
        display: none;
    }

    .photography__header__main {
        overflow: hidden;
        display: block;
        padding: 16px;

        .logo__section {
            width: 100%;
        }

        .links__list__section {
            width: 100%;

            .list__section {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: inherit;
                position: absolute;
                top: calc(30% - 0.666em);
                left: 0;
                right: 0;
                will-change: transform;
                transition: .5s transform;
                padding: 0 16px;
                width: calc(100% - 32px);

                .list__item {
                    width: 100%;
                    display: flex;
                    height: var(--itemHeight);

                    .slogan {
                        display: none;
                    }

                    div {
                        width: 28.5%;
                        font-size: 12px;
                        line-height: 16px;
                        color: #000;

                        &.description__section {
                            width: 43%;
                            text-align: end;
                        }

                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                    }
                }

                .second__card__list {
                    margin-top: calc(var(--itemHeight)* 2);
                }
            }
        }
    }
}